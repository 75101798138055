import React from 'react';

export const Shop = () => {
  return (
    <div className='Shop'>
      <header className='Shop-header'>
        Shop
      </header>
    </div>
  );
}
